export const carouselInicio = [
    // {
    //     "imagen" : "./assets/imgs/VEDA_2024.jpg"
    // },
    // {
    //     "imagen" : "./assets/imgs/banner_veda.jpg"
    // }
    {
        "imagen" : "./assets/imgs/CCFerrocarril.jpg"
    },
    {
        "imagen" : "./assets/imgs/Maquinaria_Ferrocarril.jpg"
    }    
]