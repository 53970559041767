export const links = [
    {
        "nombre" : "Transparencia",
        "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/Transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Rubros Anteriores",
                "subMenuUrl" : "/Transparencia/Rubros/RubrosHistoricos"
            },
            {
                "subMenuNombre" : "Agenda de la Secretaria",
                "subMenuUrl" : "/Agenda"
            }
        ]
    },
    {
        "nombre" : "Adquisiciones",
        "link" : "/Adquisiciones",
        "submenu" : [
            /*{
                "subMenuNombre" : "Licitaciones",
                "subMenuUrl" : "/Licitaciones",
            },
            {
                "subMenuNombre" : "Información Ejercicios Anteriores",
                "subMenuUrl" : "/EjerciciosAnteriores",
            },*/
            {
                "subMenuNombre" : "PAAAS",
                "subMenuUrl" : "/Paaas",
            },
            {
                "subMenuNombre" : "Contratos",
                "subMenuUrl" : "/Contratos"
            },
            {
                "subMenuNombre" : "Oficios de Autorización",
                "subMenuUrl" : "/Autorizaciones"
            },
            {
                "subMenuNombre" : "Convocatorias",
                "subMenuUrl" : "/Convocatorias"
            },
        ]
    },
    {
        "nombre" : "Marco Integrado de Control Interno",
        "link" : "/MarcoIntegradoControlinterno",
        /*"submenu" : [
            {
                "subMenuNombre" : "Normateca",
                "subMenuUrl" : "https://culturahidalgo.gob.mx/normateca/"
            },
            {
                "subMenuNombre" : "Comité de Ética y Prevención de Conflictos de Interés",
                "subMenuUrl" : ""
            },
            {
                "subMenuNombre" : "9 Día por la Integridad",
                "subMenuUrl" : ""
            },
            {
                "subMenuNombre" : "Comité de Archivos",
                "subMenuUrl" : ""
            },
            {
                "subMenuNombre" : "Comité de Control y Desempeño Institucional",
                "subMenuUrl" : ""
            },
            {
                "subMenuNombre" : "Clima Laboral",
                "subMenuUrl" : ""
            }
        ]*/
    },
    {
        "nombre" : "Trámites y Servicios",
        "link" : "/TramitesServicios",
    },
    /*
    {
        "nombre" : "Programas y Proyectos",
        "link" : "/ProgramasProyectos",
    }
    */
]