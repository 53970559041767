import React, { useState, useEffect } from 'react';
import './rubroshistoricos.css';

const RubrosHistoricos = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

const [rubrosHistoricos, setrubrosHistoricos] = useState([])
  useEffect(() => {
    fetch('/assets/json/rubrosHistoricos.json')
      .then((response) => {
        return response.json()
      })
      .then((rubrosHistoricos) => {
        setrubrosHistoricos(rubrosHistoricos)
      })
  }, []);

  return (
    <div className='container'>
        <div className='row'>
            <div className='col-12'>
                <h3 className='title'>Información Histórica</h3>
                <hr className='hr-gob'/>
            </div>
            {rubrosHistoricos.map((rubrosHistoricos) => {
                return (
                <>
                <div className='col-md-3 col-sm-6 col-12'>
                    <div className='card_container_historicos'>
                        <div className='card_anio'>
                            <h5>{ rubrosHistoricos.anio }</h5>
                            <h6>{ rubrosHistoricos.descripcion }</h6>
                            {rubrosHistoricos.trimestres.map((trimestres) => {
                                let url;
                                if(rubrosHistoricos.anio == '2015 - 2017'){
                                  url = 'https://consultapublicamx.plataformadetransparencia.org.mx/vut-web/faces/view/consultaPublica.xhtml?idEntidad=MTM=&idSujetoObligado=MjEzMQ==#inicio';
                                }else{
                                  url = 'https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir2/Historicos/' + rubrosHistoricos.anio + '/' + trimestres.archivo;                                }
                                return (
                                <>
                                <div className='card_trimestre'>
                                    <p>{ trimestres.trimestre } <span className="badge bg-secondary">
                                        <a href={ url } target="_blank">.zip</a></span>
                                    </p> 
                                </div>
                                </>
                                );
                            })}
                        </div>
                    </div>
                </div>
                </>
              );
            })}
        </div>
    </div>
  )
}

export default RubrosHistoricos