export const programasproyectos_item = [
    
    {
        "programasproyectos_item_nombre" : "PRODICI",
        "programasproyectos_item_descripcion" : "Programa para el Desarrollo Integral de las Culturas de los Pueblos y Comunidades Indígenas",
        "programasproyectos_item_url" : " "
    },
    {
        "programasproyectos_item_nombre" : "PACMyC",
        "programasproyectos_item_descripcion" : "Programa de Apoyo a las Culturas Municipales y Comunitarias",
        "programasproyectos_item_url" : " "
    },
    {
        "programasproyectos_item_nombre" : "Programa de Diseño e Innovación Artesanal",
        "programasproyectos_item_descripcion" : "Capacitación en el uso de las tecnologías",
        "programasproyectos_item_url" : " "
    },
    {
        "programasproyectos_item_nombre" : "Programa de Desarrollo Cultural de la Huasteca",
        "programasproyectos_item_descripcion" : "Propiciar el intercambio interestatal e interinstitucional en pro del desarrollo de las manifestaciones culturales",
        "programasproyectos_item_url" : " "
    },
    {
        "programasproyectos_item_nombre" : "Programa de Operación de Espacios Culturales",
        "programasproyectos_item_descripcion" : "Atender de forma periódica y sistemática las necesidades de mantenimiento y operación",
        "programasproyectos_item_url" : "https://www.google.com"
    }
]