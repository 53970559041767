import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './tramitesservicios.css';
import { tramitesservicios_item } from '../../json/tramitesserviciosItem';

const TramitesServicios = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container tramitesservicios-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='tramitesservicios_title'>
                    <h1>Trámites y servicios</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                tramitesservicios_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 tramitesservicios_item'>
                        
                        <Link to={ item.tramitesservicios_item_url }>{ item.tramitesservicios_item_nombre }</Link>
                        <p>{ item.tramitesservicios_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default TramitesServicios