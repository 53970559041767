import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './convocatorias.css';

const Convocatorias = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container convocatorias-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='convocatorias_title'>
                    <h1>Convocatorias</h1>
                </div>
            </div>
        </div>

        <div className='row'>

          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2024">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2024" aria-expanded="false" aria-controls="collapseOne2024">2024</button>
              </h2>
              <div id="collapseOne2024" class="accordion-collapse collapse" aria-labelledby="headingOne2024" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                  <li class="list-group-item">CONVOCATORIA_0001.pdf<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Convocatorias/CONVOCATORIA_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>
    </div>
  )
}

export default Convocatorias