import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './marcointegradocontrolinterno.css';
import { marcointegradocontrolinterno_item } from '../../json/marcointegradocontrolinternoItem';

const MarcoIntegradoControlinterno = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container marcointegradocontrolinterno-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='marcointegradocontrolinterno_title'>
                    <h1>Marco Integrado de Control Interno</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                marcointegradocontrolinterno_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 marcointegradocontrolinterno_item'>
                        
                        <Link to={ item.marcointegradocontrolinterno_item_url }>{ item.marcointegradocontrolinterno_item_nombre }</Link>
                        <p>{ item.marcointegradocontrolinterno_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default MarcoIntegradoControlinterno
