import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './licitaciones.css';

const Licitaciones = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container licitaciones-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='licitaciones_title'>
                    <h1>Licitaciones</h1>
                </div>
            </div>
        </div>

        <div className='row'>
            <div>
                <h3 className='title'>Licitaciones Públicas Nacionales</h3>
                <hr className='hr-gob'/>
            </div>
            <div class="accordion" id="accordionExample">

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOneCECULTAH-LA-18-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-18-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-18-2023">CECULTAH-LA-18-2023 (SEGUNDO PROCEDIMIENTO)| ADQUISICIÓN DE INSTRUMENTOS MUSICALES PARA LA BANDA SINFÓNICA DEL ESTADO DE HIDALGO”</button></h2>
                  <div id="collapseOneCECULTAH-LA-18-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-18-2023" data-bs-parent="#accordionExample">
                     <div class="accordion-body">
                        <ul class="list-group">
                           <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           {/*<li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}                  
                        </ul>
                     </div>
                  </div>
                </div>

               <div class="accordion-item">
                 <h2 class="accordion-header" id="headingOneCECULTAH-LA-02-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-02-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-02-2023">CECULTAH-LA-02-2023 | Contratación de Seguros</button></h2>
                 <div id="collapseOneCECULTAH-LA-02-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-02-20232023" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                       <ul class="list-group">
                          <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Respuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH-LA-02-2023_Respuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Relación de Bienes - Centro Cultural del Ferrocarril<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION%20DE%20BIENES_CENTRO_CULTURAL_DEL_FERROCARRIL.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Relación de Bienes - Casa Felipe Ángeles<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_CASA_FELIPE_ANGELES.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Relación de Bienes - Centro Cultural de Zimapán<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_CENTRO_CULTURAL_DE_ZIMAPAN.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Relación de Bienes - Museo de la Cultura Huasteca<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/RELACION_DE_BIENES_MUSEO_DE_LA_CULTURA_HUASTECA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                          <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-LA-02-2023/CECULTAH.LA.02.2023_ActaAperturaProposiciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       </ul>
                    </div>
                 </div>
               </div>

            </div>
            <div>
                <h3 className='title'>Invitación a cuando menos tres personas</h3>
                <hr className='hr-gob'/>
            </div>
            <div class="accordion" id="accordionExample1">

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOneCECULTAH-LA-18-20231"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-18-20231" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-18-20231">CECULTAH-LA-18-2023 (SEGUNDO PROCEDIMIENTO)| ADQUISICIÓN DE INSTRUMENTOS MUSICALES PARA LA BANDA SINFÓNICA DEL ESTADO DE HIDALGO”</button></h2>
                  <div id="collapseOneCECULTAH-LA-18-20231" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-18-20231" data-bs-parent="#accordionExample1">
                     <div class="accordion-body">
                        <ul class="list-group">
                           <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           {/*<li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}                  
                        </ul>
                     </div>
                  </div>
                </div>

            </div>
            <div>
                <h3 className='title'>Directas</h3>
                <hr className='hr-gob'/>
            </div>
            <div class="accordion" id="accordionExamplex">

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOneCECULTAH-LA-18-2023x"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-LA-18-2023x" aria-expanded="false" aria-controls="collapseOneCECULTAH-LA-18-2023x">CECULTAH-LA-18-2023 (SEGUNDO PROCEDIMIENTO)| ADQUISICIÓN DE INSTRUMENTOS MUSICALES PARA LA BANDA SINFÓNICA DEL ESTADO DE HIDALGO”</button></h2>
                  <div id="collapseOneCECULTAH-LA-18-2023x" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-LA-18-2023x" data-bs-parent="#accordionExamplex">
                     <div class="accordion-body">
                        <ul class="list-group">
                           <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           <li class="list-group-item">Bases<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-Bases.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           {/*<li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023_Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                           <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-LA-18-2023/CECULTAH-LA-18-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}                  
                        </ul>
                     </div>
                  </div>
                </div>

            </div>

        </div>
    </div>
  )
}

export default Licitaciones