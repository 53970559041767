import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './inicio.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselInicio } from '../../json/carousel';

const Inicio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Carousel bannerData={carouselInicio} search={true} name="banner-principal" />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='secretaria_logo' >
              <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Cultura.svg")` }}>

              </div>
            </div>
          </div>
          <div className='secretaria_name'>
            <h1>SECRETARÍA DE CULTURA</h1>
          </div>
          <div className='secretaria_funciones'>
            <h3 className='title'>FUNCIONES</h3>
            <hr className='hr-gob'/>
            <p>La Secretaría de Cultura tiene a su cargo el desempeño de las atribuciones y facultades que le confieren expresamente la Ley Orgánica de la Administración Pública para el Estado de Hidalgo, las demás Leyes, Reglamentos, Decretos, Acuerdos y Convenios vigentes en el ámbito de su competencia, así como las que le confiera y delegue el Ejecutivo del Estado. De las facultades y obligaciones indelegables de la Secretaria de Cultura </p>
            <p> I.  Conducir la política cultural del Estado, así como planear, coordinar y evaluar las actividades de la Secretaría en los términos de la legislación aplicable, a fin de dar cumplimiento a los objetivos contenidos en el Plan Estatal de Desarrollo; </p>
            <p> II. Acordar con el Gobernador del Estado de Hidalgo los asuntos relevantes con la Secretaría de Cultura;</p>
            <p> III.  Someter a la consideración del Ejecutivo del Estado, los programas sectoriales, regionales y especiales; así como la formulación de los programas anuales correspondientes en los términos de la legislación aplicable sustentados en el Plan Estatal de Desarrollo; </p>
            <p> IV. Someter a acuerdo del Ejecutivo del Estado, los asuntos encomendados a la Secretaría e informarle sobre el desarrollo de las actividades de las unidades administrativas adscritas a ésta; así como del desempeño de las comisiones y funciones que le sean conferidas; </p>
            <p> V.  Proponer al Ejecutivo del Estado, a través de las instancias correspondientes, los proyectos de leyes, reglamentos, decretos, manuales, acuerdos y órdenes relativos a los asuntos que son competencia de la Secretaría y de las Entidades coordinadas por ella; </p>   
              <div className='btn_organigrama'>
                <button type="button" className="btn btn-primary">Organigrama</button>
                <button type="button" className="btn btn-primary">Normatividad </button>
              </div>
              <div className='secretaria-redes'>
                <a href='https://www.facebook.com/CulturaHidalgo' target='_blank'><i class="fa-brands fa-facebook-f"></i></a>
                <a href='https://www.instagram.com/culturahgo/' target='_blank'><i class="fa-brands fa-instagram"></i></a>
                <a href='https://twitter.com/CulturaHidalgo' target='_blank'><i class="fa-brands fa-twitter"></i></a>
              </div>
          </div>
        </div>
        <UltimasNoticias/>
        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>TITULAR DE LA SECRETARÍA</h3>
              <hr className='hr-gob'/>
            </div>
            <div className='section_notes'>
              {
                titular.map((item, index) =>(
                    <>
                      <div key={ index } className='sec_photo'>
                        <div className='img_secretario' style={{ backgroundImage: `url("${ item.imagen }")` }}>
                        </div>
                      </div>
                      <div className='sec_container'>
                        <div className='sec_name'>
                          <h3>{ item.nombre }</h3>
                        </div>
                        <div className='sec_function'>
                          <h4>Función de la Persona titular de la Secretaría</h4>
                          <p>{ item.funcion }</p>
                          <div className='btn_agenda'>
                            <Link to="Agenda">Conoce la agenda</Link>
                          </div>
                        </div>
                      </div>
                    </>
                ))
              }
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>UBICACIÓN</h3>
                <hr className='hr-gob'/>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d936.5954440327968!2d-98.73605803040061!3d20.118114158110288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d109e313a42fd3%3A0xecc4cb258f1a3a4!2sCentro%20de%20Cultura%20Digital!5e0!3m2!1ses-419!2smx!4v1724440506006!5m2!1ses-419!2smx"
                      width="100%" 
                      height="300" 
                      frameborder="0" 
                      style={{border:0, marginBottom:50}} 
                      allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>   
      </div>
    </>
  )
}

/*
KCL 6/06/2023
- Quitamos seccion de noticias
<UltimasNoticias/>

<iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=palacio%20de%20gobierno%20pachuca%20hidalgo+(Mi%20nombre%20de%20egocios)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      width="100%" 
                      height="300" 
                      frameborder="0" 
                      style={{border:0, marginBottom:50}} 
                      allowfullscreen>
              </iframe>
*/
export default Inicio