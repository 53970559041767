export const tramitesservicios_item = [
    
    {
        "tramitesservicios_item_nombre" : "PACMyC",
        "tramitesservicios_item_descripcion" : "Programa de Apoyo a las Culturas Municipales y Comunitarias",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/685"
    },
    {
        "tramitesservicios_item_nombre" : "Culturas Populares",
        "tramitesservicios_item_descripcion" : "Oficio de petición para fomento a las culturas populares",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/680"
    },
    {
        "tramitesservicios_item_nombre" : "Prestamos de espacios",
        "tramitesservicios_item_descripcion" : "Préstamo de espacios adscritos al CECULTAH",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/473"
    },
    {
        "tramitesservicios_item_nombre" : "Talleres Artísticos Libres",
        "tramitesservicios_item_descripcion" : "Inscripción y reinscripción, Centro de las Artes de Hidalgo",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/583"
    },
    {
        "tramitesservicios_item_nombre" : "Talleres, Cursos, Seminarios y/o Diplomados Especializados",
        "tramitesservicios_item_descripcion" : "Inscripción y reinscripción, Centro de las Artes de Hidalgo",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/585"
    },
    {
        "tramitesservicios_item_nombre" : "Educación Artística Inicial",
        "tramitesservicios_item_descripcion" : "Entrevistas, inscripción y reinscripción, Centro de las Artes de Hidalgo",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/566"
    },
    {
        "tramitesservicios_item_nombre" : "Solicitud de becas",
        "tramitesservicios_item_descripcion" : "Para eximir del pago de reinscripción al Sistema de Educación Artística",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/7059"
    },
    {
        "tramitesservicios_item_nombre" : "Estimulación Temprana",
        "tramitesservicios_item_descripcion" : "Escuela de Música del Estado de Hidalgo",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/664"
    },
    {
        "tramitesservicios_item_nombre" : "Iniciación Musical",
        "tramitesservicios_item_descripcion" : "Escuela de Música del Estado de Hidalgo",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/667"
    },
    {
        "tramitesservicios_item_nombre" : "Materia Libre",
        "tramitesservicios_item_descripcion" : "Escuela de Música del Estado de Hidalgo",
        "tramitesservicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/670"
    }
    
    /*{
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "Rubros"
    },
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "Rubros"
    },
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "Rubros"
    }*/
]