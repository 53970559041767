export const marcointegradocontrolinterno_item = [
    
    {
        "marcointegradocontrolinterno_item_nombre" : "Normateca",
        "marcointegradocontrolinterno_item_descripcion" : "",
        "marcointegradocontrolinterno_item_url" : "https://culturahidalgo.gob.mx/normateca/"
    },
    {
        "marcointegradocontrolinterno_item_nombre" : "Comité de Ética y Prevención de Conflictos de Interés",
        "marcointegradocontrolinterno_item_descripcion" : "",
        "marcointegradocontrolinterno_item_url" : "https://culturahidalgo.gob.mx/normateca/"
    },
    {
        "marcointegradocontrolinterno_item_nombre" : "9 Día por la Integridad",
        "marcointegradocontrolinterno_item_descripcion" : "",
        "marcointegradocontrolinterno_item_url" : "https://culturahidalgo.gob.mx/normateca/"
    },
    {
        "marcointegradocontrolinterno_item_nombre" : "Comité de Archivo",
        "marcointegradocontrolinterno_item_descripcion" : "",
        "marcointegradocontrolinterno_item_url" : "https://culturahidalgo.gob.mx/normateca/"
    },
    {
        "marcointegradocontrolinterno_item_nombre" : "Comité de Control y Desempeño Instituciona",
        "marcointegradocontrolinterno_item_descripcion" : "",
        "marcointegradocontrolinterno_item_url" : "https://culturahidalgo.gob.mx/normateca/"
    },
    {
        "marcointegradocontrolinterno_item_nombre" : "Clima Laboral",
        "marcointegradocontrolinterno_item_descripcion" : "",
        "marcointegradocontrolinterno_item_url" : "https://culturahidalgo.gob.mx/normateca/"
    }
    
]