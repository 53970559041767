export const transparencia_item = [
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "/Transparencia/Rubros"
    },
    {
        "transparencia_item_nombre" : "Rubros Anteriores",
        "transparencia_item_url" : "/Transparencia/Rubros/RubrosHistoricos"
    },
    {
        "transparencia_item_nombre" : "Agenda de la Secretaria",
        "transparencia_item_url" : "/Agenda"
    },
    /*{
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "Rubros"
    },
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "Rubros"
    },
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "Rubros"
    }*/
]