export const ultimasNoticias = [
    // {
    //     "imagen" : "https://cdn.hidalgo.gob.mx/transicion/feria.png",
    //     "url" : "www.gooogle.com",
    //     "titulo" : "Aqui va el titulo 1",
    //     "fecha" : "01 de marzo del 2023"
    // },
    // {
    //     "imagen" : "https://cdn.hidalgo.gob.mx/transicion/feria.png",
    //     "url" : "www.gooogle.com",
    //     "titulo" : "Aqui va el titulo 2",
    //     "fecha" : "02 de marzo del 2023"
    // },
    {
        "imagen" : "https://cdn.hidalgo.gob.mx/convocatorias/2024/convocatoria1.png",
        "url" : "https://url.hidalgo.gob.mx/SFfeAD",
        "titulo" : "XIX Concurso Nacional “Transparencia en Corto”",
        "fecha" : "17-06-2024"
    }
]