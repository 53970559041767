export const titular = [
    // {
    //     "nombre" : "Tania Eréndira Meza Escorza",
    //     "imagen" : "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/TaniaMezaEscorza.png",
    //     "funcion" : "Corresponde originalmente a la Secretaria la representación, trámite y resolución de los asuntos que compete a la Secretaría de Cultura. La Secretaria, para la mejor organización del trabajo, podrá delegar, mediante acuerdos que deberán ser publicados en el Periódico Oficial del Estado de Hidalgo, facultades en servidoras/es públicos subalternos, salvo aquéllas que las disposiciones jurídicas señalen como indelegables, sin perjuicio de su ejercicio directo."
    // }
    // {
    //     "nombre" : "Irma Balderas Arrieta",
    //     "imagen" : "./assets/imgs/IrmaBalderasArrieta.png",
    //     "funcion" : "Corresponde originalmente a la Secretaria la representación, trámite y resolución de los asuntos que compete a la Secretaría de Cultura. La Secretaria, para la mejor organización del trabajo, podrá delegar, mediante acuerdos que deberán ser publicados en el Periódico Oficial del Estado de Hidalgo, facultades en servidoras/es públicos subalternos, salvo aquéllas que las disposiciones jurídicas señalen como indelegables, sin perjuicio de su ejercicio directo."
    // }
    {
        "nombre" : "Neyda Naranjo Baltazar",
        "imagen" : "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/NeydaNaranjoBaltazar.png",
        "funcion" : "Corresponde originalmente a la Secretaria la representación, trámite y resolución de los asuntos que compete a la Secretaría de Cultura. La Secretaria, para la mejor organización del trabajo, podrá delegar, mediante acuerdos que deberán ser publicados en el Periódico Oficial del Estado de Hidalgo, facultades en servidoras/es públicos subalternos, salvo aquéllas que las disposiciones jurídicas señalen como indelegables, sin perjuicio de su ejercicio directo."
    }
]