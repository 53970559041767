export const adquisiciones_item = [
    /*{
        "adquisiciones_item_nombre" : "Licitaciones",
        "adquisiciones_item_url" : "/Licitaciones",
    },
    {
        "adquisiciones_item_nombre" : "Información Ejercicios Anteriores",
        "adquisiciones_item_url" : "/EjerciciosAnteriores",
    },*/
    {
        "adquisiciones_item_nombre" : "Programa Anual de Adquisiciones, Arrendamientos y Servicios",
        "adquisiciones_item_url" : "/Paaas",
    },
    {
        "adquisiciones_item_nombre" : "Contratos",
        "adquisiciones_item_url" : "/Contratos"
    },
    {
        "adquisiciones_item_nombre" : "Oficios de Autorización",
        "adquisiciones_item_url" : "/Autorizaciones"
    },
    {
        "adquisiciones_item_nombre" : "Convocatorias",
        "adquisiciones_item_url" : "/Convocatorias"
    },
]