import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Transparencia from './components/Transparencia/Transparencia';
import TramitesServicios from './components/TramitesServicios/TramitesServicios';
import MarcoIntegradoControlinterno from './components/MarcoIntegradoControlinterno/MarcoIntegradoControlinterno';
import ProgramasProyectos from './components/ProgramasProyectos/ProgramasProyectos';
import Rubros from './components/Rubros/Rubros';
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import Agenda from "./components/Agenda/Agenda";
import Adquisiciones from './components/Adquisiciones/Adquisiciones';
import Paaas from './components/Paaas/Paaas';
import Licitaciones from './components/Licitaciones/Licitaciones';
import EjerciciosAnteriores from "./components/EjerciciosAnteriores/EjerciciosAnteriores";
import Invitacion from './components/Invitacion/Invitacion';
import Contratos from './components/Contratos/Contratos';
import Convocatorias from './components/Convocatorias/Convocatorias';
import Autorizaciones from './components/Autorizaciones/Autorizaciones';
import Error404 from "./components/Error404/Error404";

function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/Transparencia" element={ <Transparencia/> } />
          <Route exact path="/TramitesServicios" element={ <TramitesServicios/> } />
          <Route exact path="/ProgramasProyectos" element={ <ProgramasProyectos/> } />
          <Route exact path="/MarcoIntegradoControlinterno" element={ <MarcoIntegradoControlinterno/> } />
          <Route exact path="/Transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/Transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/Transparencia/Rubros/RubrosHistoricos" element={ <RubrosHistoricos/> } />
          <Route exact path="/Agenda" element={ <Agenda/> } />
          <Route exact path="/Adquisiciones" element={ <Adquisiciones/> } />
          <Route exact path="/Paaas" element={ <Paaas/> } />
          <Route exact path="/Licitaciones" element={ <Licitaciones/> } />
          <Route exact path="/EjerciciosAnteriores" element={ <EjerciciosAnteriores/> } />
          <Route exact path="/Invitacion" element={ <Invitacion/> } />
          <Route exact path="/Contratos" element={ <Contratos/> } />
          <Route exact path="/Convocatorias" element={ <Convocatorias/> } />
          <Route exact path="/Autorizaciones" element={ <Autorizaciones/> } />
          <Route exact path="*" element={<Error404/> } />
          <Route exact path="/pag/transparencia.html" element={<Transparencia/> } />
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
