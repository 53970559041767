import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import './agenda.css';
//import { agenda } from '../../json/agenda'; Estaba antes "Contiene los eventos"

/*ETH Traemos todos datos de la hoja de calculo*/
var filas;
let eventos = Array();
  
async function obtenerDatos() {
    const url = 'https://sheets.googleapis.com/v4/spreadsheets/1hZeclwgl0zMqu9Myc1Ji7GjSJ4pXMpuXoH-ZBYj1TfE/values:batchGet?ranges=Hoja%201&majorDimension=ROWS&key=AIzaSyC9mSps0PzUFRyC73cSO5bhHyKzSU5lE5Q';
    // Storing response
    const response = await fetch(url);
    // Storing data in form of JSON
    var data = await response.json();
    if (response) {
        for (const element of Object.entries(data)) {
            filas = element[1][0]['values'];
        }
        //ETH Quitar el primer elemento del array (La cabecera)
        filas.shift();

        var idEvento = 1;
        for (const fila of Object.values(filas)) {
            idEvento++; 
            eventos = [...eventos, {
                                'id': idEvento,
                                'title': fila[0],
                                'place': fila[1],
                                'start': fila[2],
                                'color': 'var(--wine)',
                                'description': fila[3]
                                    }
                        ];
        }
    }
    
    return eventos;
}
/*ETH Traemos todos datos de la hoja de calculo*/

const Agenda = () => {

    const [events, setEvents] = useState([]);
    const [modalStatus, setModalStatus] = useState(false);
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        /*ETH Se usa para obtener el arreglo de eventos, se debe hacer async para esperar que se obtengan los datos antes de enviarlos al fullcalendar*/
        //setEvents(agenda); Estaba antes "mandaba el arreglo de eventos"
        const obtenerEventos = async () => {
            const arrayEventos = await obtenerDatos();
            setEvents(arrayEventos);
            //console.log(arrayEventos);
        };
        obtenerEventos();
        /*ETH Se usa para obtener el arreglo de eventos, se debe hacer async para esperar que se obtengan los datos antes de enviarlos al fullcalendar*/
        window.scrollTo(0, 0);
    }, []);

    const handleEventClick = (eventClickInfo) => {
        // Obtener información del evento
        setModalStatus(true);
        const eventTitle = eventClickInfo.event.title;
        const eventPlace = eventClickInfo.event.extendedProps.place;
        const eventDescription = eventClickInfo.event.extendedProps.description;
        
        setEventData([eventTitle, eventPlace, eventDescription]);
    };

    const calendarOptions = {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        weekends: true,
        events: events,
        eventClick: handleEventClick // Agregar manejador de eventos
    };
    
    return (
        <>
            <div id='calendar'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div>
                                <h3 className='title'>Agenda de la Secretaria</h3>
                                <hr className='hr-gob'/>
                            </div>
                            {
                                modalStatus ? 
                                <div id='modal-agenda'>
                                    <div className='close-icon'>
                                        <i className="fa-sharp fa-solid fa-xmark" onClick={() => {setModalStatus(false)}}/>
                                    </div>
                                    <p className='agenda-title'>{ eventData[0] }</p>
                                    <p className='agenda-location'><i className="fa-solid fa-location-dot"></i>{ eventData[1] }</p>
                                    <iframe className='twitter-embed' srcDoc={eventData[2]}></iframe>
                                </div> :null
                            }
                            <div className='container'>
                                <div className="agenda">
                                    <FullCalendar {...calendarOptions} locale={esLocale}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>        
    )
}

export default Agenda;